<template>
  <v-row>
    <v-col>
      <v-card>
        <form action="#" @submit.prevent="submit">
          <v-card-title>
            Signup
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field name="name" label="name" v-model="name"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field name="email" label="email" v-model="email"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field name="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="password" 
                  type="password" 
                  @click:append="showPassword = !showPassword"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="success">Register</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {Auth} from '../firebase/auth.js'

  export default {
    name: 'Signup',
    data: () => ({
      name: '', 
      email: '', 
      error: '', 
      password: '', 
      showPassword: false,
    }), 
    methods: {
      submit(){
        Auth.createUserWithEmailAndPassword(this.email, this.password)
          .then( data => {
            data.user
              .updateProfile({
                displayName: this.name
              })
              .then( () => {})
          })
          .catch( err => {
            this.error = err.message;
            console.log(this.error)
          })
      }
    }
  }
</script>